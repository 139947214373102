import React from 'react';

// Use interface
import { CardInterFace } from '../../interface/common.interface';

function CardBox({ title, children, rightSide }: CardInterFace) {
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">{title}</div>
        <div className="card-title-right">{rightSide}</div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
}

export default CardBox;

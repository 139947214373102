/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import pendingReviewApi from '../../utils/api/pendingReview';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import PendingReviewReduxInterface from '../../interface/pending-review.interface';
import { changeDateTimeFormat } from '../../utils/helpers';

// Use get table custom attribute list
export const fetchPendingReviewList = createAsyncThunk(
  'pendingReview/fetchPendingReviewList',
  async (data: any, { dispatch }) => {
    const response = await pendingReviewApi
      .getPendingReview(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.list.map((val: any) => ({
              ...val,
              // id: val.pin,
              createTime: changeDateTimeFormat(val.createTime),
            })),
            size: response1.data.data.size,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: PendingReviewReduxInterface = {
  pendingReviews: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const pendingReviewSlices: any = createSlice({
  name: 'pendingReviews',
  initialState,
  reducers: {
    deletePendingReview: (state, action: any) => {
      state.pendingReviews = state.pendingReviews.filter((val) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPendingReviewList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchPendingReviewList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          if (state.currentPageNo === 1) {
            state.pendingReviews = action.payload.data.list;
          } else {
            state.pendingReviews = [...state.pendingReviews, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchPendingReviewList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deletePendingReview } = pendingReviewSlices.actions;

export default pendingReviewSlices.reducer;

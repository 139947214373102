/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use API calls
import person from '../../utils/api/person';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { personReduxInterface } from '../../interface/personnel.interface';
import { changeDateTimeFormat } from '../../utils/helpers';

// / Define the async thunk for fetching a single person's details
export const fetchSinglePerson = createAsyncThunk(
  'persons/fetchSinglePerson',
  async (id: string, { dispatch }) => {
    try {
      const response = await person.getPerson(id); // Call the API to get person details
      const personData = response.data;

      // Process the personPhoto if it exists
      const processedPersonData = {
        ...personData,
        data: {
          ...personData.data,
          personPhoto: personData.data.personPhoto
            ? `data:image/png;base64,${personData.data.personPhoto}`
            : null,
        },
      };
      return processedPersonData;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data)); // Handle error notification
      throw error; // Return the error to handle it in the reducer
    }
  },
);

// Define the async thunk for fetching the persons list
export const fetchPersonsList = createAsyncThunk(
  'persons/fetchPersonsList',
  async (data: any, { dispatch }) => {
    const response = await person
      .getPersons(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.list.map((val: any) => ({
              ...val,
              id: val.pin,
              person_id: val.id,
              personPhoto: val.personPhoto ? `data:image/png;base64,${val.personPhoto}` : null,
              createTime: changeDateTimeFormat(val.createTime),
              status: 1,
              appLogin: 1,
            })),
            size: response1.data.data.size,
            ...data,
          },
        };
        // Log the processed data to check for 'id'
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Define the async thunk for fetching the persons list
export const fetchStatistics = createAsyncThunk(
  'persons/fetchStatistics',
  async (data: any, { dispatch }) => {
    const response = await person
      .getStatistics(data)
      .then((response1) => {
        return {
          ...response1.data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Define the async thunk for fetching the persons list
export const fetchCardTemplates = createAsyncThunk(
  'persons/fetchCardTemplates',
  async (data: any, { dispatch }) => {
    const response = await person
      .getPrintCardTemplate(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: response1.data.data.map((val: any) => ({
            id: val.id,
            label: val.name,
          })),
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Define the initial state
const initialState: personReduxInterface = {
  persons: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  singlePerson: {},
  statistics: {},
  cardTemplates: [],
};

// Create the slice
export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    addPerson: (state, action: any) => {
      if (state.persons.find((val: any) => val.id === action.payload.id)) {
        state.persons = state.persons.map((val: any) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
            output.createTime = changeDateTimeFormat(action.payload.createTime);
            output.personPhoto = action.payload.personPhoto
              ? `data:image/png;base64,${action.payload.personPhoto}`
              : null;
          }
          return output;
        });
      } else {
        state.persons = [
          {
            ...action.payload,
            id: action.payload.pin,
            createTime: changeDateTimeFormat(action.payload.createTime),
            personPhoto: action.payload.personPhoto
              ? `data:image/png;base64,${action.payload.personPhoto}`
              : null,
          },
          ...state.persons,
        ];
        state.totalDataCount += 1;
        if (state.persons.length > 10) {
          state.persons.pop();
        }
      }
    },
    getSinglePerson: (state, action: any) => {
      state.singlePerson = state.persons.find((val) => val.pin === action.payload);
    },
    removeSinglePerson: (state) => {
      state.singlePerson = {};
    },
    deletePerson: (state, action: any) => {
      // Filter out the deleted person from the `persons` list based on their unique identifier (e.g., `id` or `code`)
      state.persons = state.persons.filter((val: { id: string }) => val.id !== action.payload);

      // Decrement the total count of persons in the state
      state.totalDataCount -= 1;
    },
    resetSelfPwd: (state, action: any) => {
      // Filter out the deleted person from the `persons` list based on their unique identifier (e.g., `id` or `code`)
      state.persons = state.persons.filter((val: { id: string }) => val.id !== action.payload);
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPersonsList.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          if (state.currentPageNo === 1) {
            state.persons = action.payload.data.list;
          } else {
            state.persons = [...state.persons, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchPersonsList.rejected, (state) => {
        state.status = 'success';
      });
    builder
      .addCase(fetchStatistics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStatistics.fulfilled, (state, action: any) => {
        if (action.payload.success) {
          state.statistics = action.payload.data;
        }
        state.status = 'success';
      })
      .addCase(fetchStatistics.rejected, (state) => {
        state.status = 'success';
      });

    // Handle fetching a single person's data
    builder
      .addCase(fetchSinglePerson.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSinglePerson.fulfilled, (state, action: any) => {
        state.singlePerson = action.payload.data; // Set the person details in state
        state.status = 'success';
      })
      .addCase(fetchSinglePerson.rejected, (state) => {
        state.status = 'success';
      });

    // Handle fetching a card templates list
    builder
      .addCase(fetchCardTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCardTemplates.fulfilled, (state, action: any) => {
        state.cardTemplates = action.payload.data; // Set the person details in state
        state.status = 'success';
      })
      .addCase(fetchCardTemplates.rejected, (state) => {
        state.status = 'success';
      });
  },
});

// Export the action creators
export const {
  setSearchFilter,
  addPerson,
  getSinglePerson,
  removeSinglePerson,
  deletePerson,
  resetSelfPwd,
} = personSlice.actions;

// Export the reducer
export default personSlice.reducer;

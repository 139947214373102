import React from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shard components
import Button from '../button/button';

// Use interface
import { ConformationModalBoxInterFace } from '../../interface/common.interface';

function ConformationBox({
  closeModal,
  title,
  okFunction,
  buttonLabel,
}: ConformationModalBoxInterFace) {
  const { t } = useTranslation();
  return (
    <div className="conformation-box">
      <div className="message-text">
        <p>{title}</p>
      </div>
      <div className="confirm-buttons">
        <Button onClickFunction={closeModal} title={t('Cancel')} className="btn-default" />
        <Button onClickFunction={okFunction} title={buttonLabel} className="btn-primary" />
      </div>
    </div>
  );
}

export default ConformationBox;

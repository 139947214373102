/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import library from '../../utils/api/library';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { LibraryReduxInterface } from '../../interface/library.interface';

// Use assets json file
import libraryType from '../../assets/json/library-type.json';

// Use get table library list
export const fetchLibraryList = createAsyncThunk(
  'library/fetchLibraryList',
  async (data: any, { dispatch }) => {
    const response = await library
      .getLibrary(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.list.map((val: any) => ({
              ...val,
              libraryType: libraryType.find((val1) => val1.id === val.type)?.label,
            })),
            size: response1.data.data.size,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Use get all library list
export const fetchAllLibraryList = createAsyncThunk(
  'library/fetchAllLibraryList',
  async (data: any, { dispatch }) => {
    const response = await library
      .getAllLibrary()
      .then((response1) => {
        console.log(response1);
        return {
          ...response1.data,
          data: response1.data.data.map((val: any) => ({
            id: val.code,
            label: val.name,
          })),
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: LibraryReduxInterface = {
  libraries: [],
  allLibraries: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const librarySlice: any = createSlice({
  name: 'library',
  initialState,
  reducers: {
    addLibrary: (state, action: any) => {
      if (state.libraries.find((val) => val.id === action.payload.id)) {
        state.libraries = state.libraries.map((val) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
            output.libraryType = libraryType.find((val1) => val1.id === action.payload.type)?.label;
          }
          return output;
        });
        state.allLibraries = state.allLibraries.map((val) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = {
              label: action.payload.name,
              id: action.payload.id,
            };
          }
          return output;
        });
      } else {
        state.libraries = [
          ...state.libraries,
          {
            ...action.payload,
            libraryType: libraryType.find((val1) => val1.id === action.payload.type)?.label,
          },
        ];
        state.allLibraries = [
          ...state.allLibraries,
          {
            label: action.payload.name,
            id: action.payload.id,
          },
        ];
        state.totalDataCount += 1;
      }
    },
    deleteLibrary: (state, action: any) => {
      state.libraries = state.libraries.filter((val) => val.id !== action.payload);
      state.totalDataCount -= 1;
      state.allLibraries = state.allLibraries.filter((val) => val.id !== action.payload);
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchLibraryList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchLibraryList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          if (state.currentPageNo === 1) {
            state.libraries = action.payload.data.list;
          } else {
            state.libraries = [...state.libraries, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchLibraryList.rejected, (state: any) => {
        state.status = 'success';
      });
    builder
      .addCase(fetchAllLibraryList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAllLibraryList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.allLibraries = action.payload.data;
        }
        state.status = 'success';
      })
      .addCase(fetchAllLibraryList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, addLibrary, deleteLibrary } = librarySlice.actions;

export default librarySlice.reducer;

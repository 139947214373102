/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import customAttributeApi from '../../utils/api/customAttribute';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { CustomAttributeReduxInterface } from '../../interface/attribute.interface';

import inputType from '../../assets/json/input-type.json';

// Use get table custom attribute list
export const fetchCustomAttributeList = createAsyncThunk(
  'customAttribute/fetchCustomAttributeList',
  async (data: any, { dispatch }) => {
    const response = await customAttributeApi
      .getCustomAttribute(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.list.map((val: any) => ({
              ...val,
              personTypeLabel: val.personType === 1 ? 'Yes' : 'No',
              controlTypeLabel: inputType.find((val1) => val1.id === val.controlType)?.label,
            })),
            size: response1.data.data.size,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: CustomAttributeReduxInterface = {
  customAttribute: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const customAttributesSlices: any = createSlice({
  name: 'customAttribute',
  initialState,
  reducers: {
    addCustomAttribute: (state, action: any) => {
      if (state.customAttribute.find((val) => val.id === action.payload.id)) {
        state.customAttribute = state.customAttribute.map((val) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = {
              ...action.payload,
              personTypeLabel: action.payload.personType === 1 ? 'Yes' : 'No',
              controlTypeLabel: inputType.find((val1) => val1.id === action.payload.controlType)
                ?.label,
            };
          }
          return output;
        });
      } else {
        state.customAttribute = [
          ...state.customAttribute,
          {
            ...action.payload,
            personTypeLabel: action.payload.personType === 1 ? 'Yes' : 'No',
            controlTypeLabel: inputType.find((val1) => val1.id === action.payload.controlType)
              ?.label,
          },
        ];
        state.totalDataCount += 1;
      }
    },
    deleteCustomAttribute: (state, action: any) => {
      state.customAttribute = state.customAttribute.filter((val) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchCustomAttributeList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomAttributeList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;
          console.log(state.currentPageNo);

          if (state.currentPageNo === 1) {
            state.customAttribute = action.payload.data.list;
          } else {
            state.customAttribute = [...state.customAttribute, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchCustomAttributeList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, addCustomAttribute, deleteCustomAttribute } =
  customAttributesSlices.actions;

export default customAttributesSlices.reducer;

import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { ParameterInterface } from '../../../interface/parameter.interface';

// Use interface

function PendingPersonnelSetting({ onChangeFunction, values }: ParameterInterface) {
  const { t } = useTranslation();
  const [value, setValue] = useState(values);

  const onChagne = (name: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  return (
    <div className="personnel-setting">
      <div className="personnel-setting-form">
        <div className="column">
          <div className="item">
            <RadioButtonField
              label={t('enableAutoAudit')}
              name="pers.tempPerson.audit"
              aliment="row"
              required
              data={[
                {
                  label: t('yes'),
                  id: '1',
                  value: '1',
                },
                {
                  label: t('no'),
                  id: '0',
                  value: '0',
                },
              ]}
              value={value['pers.tempPerson.audit']}
              onChangeFunction={onChagne}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingPersonnelSetting;

import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import EditDismissedPersonnel from './components/edit-dismissed-personnel';

// Use redux functions
import {
  fetchDismissalPersonsList,
  reinstatementDismissedPerson,
  setSearchFilter,
} from '../../redux/slices/DismissalPersonSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { fetchPersonsList } from '../../redux/slices/PersonSlices';

// Use api calls
import PersonApi from '../../utils/api/person';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';

function DismissedPersonnel() {
  const { t } = useTranslation();
  let sortTime = true;
  const dispatch = useAppDispatch();

  const { dismissalPersons, status, totalDataCount, currentPageNo, searchFilter } = useAppSelector(
    (state: any) => state.dismissalPerson,
  );

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDismissalPersonsList({ ...params }));
    }
  };
  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
    },
    {
      accessorKey: 'hireDate',
      header: t('hireDate'),
    },
    {
      accessorKey: 'leaveDate',
      header: t('dismissalDate'),
    },
    {
      accessorKey: 'leaveType',
      header: t('dismissalType'),
      Cell: ({ row }: any) => t(row.original.dismissalType),
    },
    {
      accessorKey: 'leaveReason',
      header: t('dismissalReason'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });

  const reinstatementRecord = (id: any) => {
    PersonApi.reinstatedDismissedPersons({ pin: id.pin })
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('dismissalPersonnelReinstatedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(reinstatementDismissedPerson(response.data.data.pin));
          dispatch(fetchPersonsList({ pageNo: 1 }));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('editDismissedPersonnel'),
          content: <EditDismissedPersonnel closeModal={() => setModalOpen(false)} data={val} />,
        });
      },
    },
    {
      label: t('reinstatement'),
      function: reinstatementRecord,
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });

  useEffect(() => {
    if (dismissalPersons.length === 0 && sortTime) {
      sortTime = false;
      fetchData({ pageNo: 1 });
    }
  }, []);
  return (
    <div className="dismissed-personnel">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('dismissedPersonnel')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('dismissedPersonnel'),
          },
        ]}
      />
      <CardBox title={t('listOfDismissedPersonnel')}>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          values={dismissalPersons}
          fixedColumn={['pin']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default DismissedPersonnel;

import React, { useState, useRef, useEffect } from 'react';

// Use interface
import { TabModalBoxInterFace, ModalTabItemInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { CloseIcon } from '../../assets/svgicons/svgicon';

function TabModalBox({ title, tabs, closeModal, status, currentIndex = 0 }: TabModalBoxInterFace) {
  const ref = useRef<HTMLDivElement>(null);
  const [tab, changeTab] = useState({
    key: '',
    title: '',
    content: <>fd</>,
  });

  const handleClickOutside = (event: any) => {
    if (ref.current === event.target) {
      closeModal();
    }
  };

  useEffect(() => {
    changeTab(tabs[currentIndex]);
  }, [tabs]);
  return (
    <div
      className={`tab-modal ${status && 'show'}`}
      onClick={handleClickOutside}
      role="presentation"
      ref={ref}>
      <div className="tab-modal-content">
        <div className="tab-modal-left">
          <div className="tab-title">{title}</div>
          <div className="tab-list-items">
            {tabs.map((tabItem: ModalTabItemInterFace) => (
              <div
                className={`tab-item ${tabItem.key === tab.key && 'active'}`}
                onClick={() => changeTab(tabItem)}
                role="presentation">
                {tabItem.title}
              </div>
            ))}
          </div>
        </div>
        <div className="tab-modal-right">
          <div className="modal-header">
            <div className="modal-title">{tab.title}</div>
            <span className="close" onClick={closeModal} role="presentation">
              <CloseIcon color="#000" />
            </span>
          </div>
          <div className="modal-body">{tab.content}</div>
        </div>
      </div>
    </div>
  );
}

export default TabModalBox;

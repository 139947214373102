import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import DatePickerField from '../../../shared-components/date-picker-field/date-picker-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import { ProfessionalDetailsProps } from '../../../interface/personnel.interface';

// Use json file
import certificateData from '../../../assets/json/certificate-type.json';

function ProfessionalDetails({
  onChangeFunction,
  values,
  allDepartments,
  allPositions,
}: ProfessionalDetailsProps) {
  const { t } = useTranslation();
  const selectData = certificateData.map((item) => ({
    label: t(item.label),
    id: item.id,
  }));
  const [value, setValue] = useState(values);
  // const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (name: string, changeValue: any) => {
    if (name === 'pin' && changeValue.length > 9) {
      setErrorMessage(t('MaxLengthError'));
      return;
    }
    setErrorMessage('');
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);

  return (
    <div className="personal-details">
      <div className="personal-details-form">
        <div className="row-form-warp">
          <div className="item">
            <InputField
              name="pin"
              label={t('personalId')}
              id="pin"
              type="text"
              value={value.pin}
              required
              readonly={!!value.id}
              onChangeFunction={onChange}
              errorStatus={errorMessage !== ''}
              message={errorMessage}
            />
            {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
          </div>
          <div className="item">
            <DatePickerField
              name="hireDate"
              label={t('hireDate')}
              value={value.hireDate}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="certType"
              label={t('certificateType')}
              id="certType"
              value={value.certType}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
          <div className="item">
            <InputField
              name="certNumber"
              label={t('certificateNumber')}
              id="certNumber"
              type="text"
              value={value.certNumber}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="deptCode"
              label={t('department')}
              id="deptCode"
              value={value.deptCode}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={allDepartments} // Use the department data
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="position"
              label={t('positionName')}
              id="position"
              value={value.position}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={allPositions}
            />
          </div>
          <div className="item">
            <InputField
              name="deviceVerificationPassword"
              label={t('deviceVerificationPassword')}
              id="deviceVerification"
              type="text"
              value={value.deviceVerification}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="biometrics"
              label={t('biometrics')}
              id="biometrics"
              type="text"
              value={value.biometrics}
              innerIconStatus={false}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="cardNo"
              label={t('issueCardNumbers')}
              id="cardNo"
              type="text"
              value={value.cardNo}
              innerIconStatus={false}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalDetails;

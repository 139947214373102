/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import person from '../../utils/api/person';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { DismissedPersonReduxInterFace } from '../../interface/dismissed-personnel.interface';
import { changeDateTimeFormat } from '../../utils/helpers';

// Use asset json file
import dismissalType from '../../assets/json/dismissal-type.json';

export const fetchDismissalPersonsList = createAsyncThunk(
  'persons/fetchDismissalPersonsList',
  async (data: any, { dispatch }) => {
    const response = await person
      .getDismissedPersons(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            ...response1.data.data,
            list: response1.data.data.list.map((val: any) => ({
              ...val,
              hireDate: changeDateTimeFormat(val.hireDate),
              leaveDate: changeDateTimeFormat(val.leaveDate),
              dismissalType: dismissalType.find((val1: any) => val1.id === val.leaveType)?.label,
            })),
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: DismissedPersonReduxInterFace = {
  dismissalPersons: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const dismissalPersonnelSlice: any = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    editDismissedPerson: (state, action: any) => {
      state.dismissalPersons = state.dismissalPersons.map((val: any) => {
        let output = val;
        if (val.pin === action.payload.pin) {
          output = {
            ...action.payload,
            hireDate: changeDateTimeFormat(action.payload.hireDate),
            leaveDate: changeDateTimeFormat(action.payload.leaveDate),
            dismissalType: dismissalType.find((val1: any) => val1.id === action.payload.leaveType)
              ?.label,
          };
        }
        return output;
      });
    },
    reinstatementDismissedPerson: (state, action: any) => {
      state.dismissalPersons = state.dismissalPersons.filter((val) => val.pin !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDismissalPersonsList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchDismissalPersonsList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          if (state.currentPageNo === 1) {
            state.dismissalPersons = action.payload.data.list;
          } else {
            state.dismissalPersons = [...state.dismissalPersons, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchDismissalPersonsList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, editDismissedPerson, reinstatementDismissedPerson } =
  dismissalPersonnelSlice.actions;

export default dismissalPersonnelSlice.reducer;

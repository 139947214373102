import React, { useState, useRef, useEffect } from 'react';
import cookies from 'js-cookie';

// Use interface
import { DropDownInterFace, DropDownMenuInterFace } from '../../interface/common.interface';

function OutsideClickHandler({ onOutsideClick, children }: any): any {
  const ref = useRef<HTMLDivElement>(null);

  useEffect((): any => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  }, [onOutsideClick]);

  return <div ref={ref}>{children}</div>;
}

function DropDown({ name, action, clickFunction }: DropDownInterFace) {
  const currentLanguageCode = cookies.get('i18next');

  const [dropdown, openDropDown] = useState(false);
  const click = (menu: DropDownMenuInterFace): void => {
    clickFunction(menu);
    openDropDown(false);
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        openDropDown(false);
      }}>
      <div className="drop-down">
        <div
          className="drop-down-label"
          onClick={() => openDropDown(!dropdown)}
          role="presentation">
          {name}
        </div>
        <div
          className={`drop-down-container ${dropdown && 'active'} ${currentLanguageCode === 'ar' ? 'left' : 'right'}`}>
          <div className="drop-down-menus">
            {action.map((menu: DropDownMenuInterFace) => (
              <div className="menu" onClick={() => click(menu)} role="presentation">
                <div className="image-icon">{menu.icon}</div>
                <div className="label">{menu.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default DropDown;

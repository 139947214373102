import React from 'react';
import { CheckBoxActiveIcon, CheckBoxIcon } from '../../assets/svgicons/svgicon';
import { NormalTableComponentInterFace } from '../../interface/common.interface';

// Use interface
// import { InputFieldInterFace } from '../../interface/common.interface';

function Table({
  header,
  value,
  returnKey = 'id',
  checkBoxStatus = false,
  selectedValues = [],
  onSelectData = () => {},
}: NormalTableComponentInterFace) {
  const selectRow = (selectData: any) => {
    if (selectedValues.includes(selectData[returnKey])) {
      onSelectData([...selectedValues.filter((val) => val !== selectData[returnKey])]);
    } else {
      onSelectData([...selectedValues, selectData[returnKey]]);
    }
  };
  return (
    <table className="table">
      <thead>
        <tr>
          {checkBoxStatus && (
            <td>
              <div className="checkbox-icon">
                {selectedValues.length === value.length ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
              </div>
            </td>
          )}
          {header.map((head) => (
            <td>{head.label}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {value.map((dataValue) => (
          <tr>
            {checkBoxStatus && (
              <td>
                <div
                  className="checkbox-icon"
                  onClick={() => selectRow(dataValue)}
                  role="presentation">
                  {selectedValues.includes(dataValue[returnKey]) ? (
                    <CheckBoxActiveIcon />
                  ) : (
                    <CheckBoxIcon />
                  )}
                </div>
              </td>
            )}
            {header.map((head) => (
              <td>{dataValue[head.id]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;

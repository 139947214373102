import React from 'react';

// Use common modules
import { Navigate, Route, Routes } from 'react-router-dom';

// Use Pages
import Layout from '../layout';
import Login from '../login';
import ForgotPassword from '../forgotpassword';
import Department from '../department';
import Dashboard from '../dashboard';
import Position from '../position';
import DismissedPersonnel from '../dismissed-personnel';
import PendingReview from '../pending-review';
import CustomAttributes from '../custom-attributes';
import ListLibrary from '../list-library';
import Parameters from '../parameters';
import CardManagement from '../card-management';
import WiegandFormat from '../wiegand-format';
import IssueCardRecord from '../issue-card-record';
import Person from '../person';
import AddPerson from '../person/components/add-person';
import AuthGuard from './auth-guard';
import EditPerson from '../person/components/edit-person';

function Router() {
  const routes = [
    {
      path: '',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="dashboard" />,
        },
      ],
    },
    {
      path: 'dashboard',
      children: [
        {
          id: 1,
          path: '',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: 'personnel',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="personnel/person" />,
        },
        {
          id: 2,
          path: 'personnel/person',
          element: <Person />,
        },
        {
          id: 3,
          path: 'personnel/person/add',
          element: <AddPerson />,
        },
        {
          id: 4,
          path: 'personnel/person/edit/:id',
          element: <EditPerson />,
        },
        {
          id: 5,
          path: 'personnel/department',
          element: <Department />,
        },
        {
          id: 6,
          path: 'personnel/position',
          element: <Position />,
        },
        {
          id: 7,
          path: 'personnel/dismissed-personnel',
          element: <DismissedPersonnel />,
        },
        {
          id: 8,
          path: 'personnel/pending-review',
          element: <PendingReview />,
        },
        {
          id: 9,
          path: 'personnel/custom-attributes',
          element: <CustomAttributes />,
        },
        {
          id: 10,
          path: 'personnel/list-library',
          element: <ListLibrary />,
        },
        {
          id: 11,
          path: 'personnel/parameters',
          element: <Parameters />,
        },
        {
          id: 12,
          path: 'card-management/card',
          element: <CardManagement />,
        },
        {
          id: 13,
          path: 'card-management/wiegand-format',
          element: <WiegandFormat />,
        },
        {
          id: 14,
          path: 'card-management/issued-card-record',
          element: <IssueCardRecord />,
        },
      ],
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/admin" element={<Layout />}>
        {routes.map((route) => (
          <Route path={route.path}>
            {route.children.map((subRoute) => (
              <Route
                key={subRoute?.id}
                path={subRoute?.path}
                element={<AuthGuard Component={subRoute?.element} />}
              />
            ))}
          </Route>
        ))}
      </Route>
    </Routes>
  );
}

export default Router;

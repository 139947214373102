import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use interface
import { SideBarSessionInterFace, SubmenuInterFace } from '../../interface/common.interface';
import { CardHandIcon, DownArrowIcon, PersonIcon } from '../../assets/svgicons/svgicon';

// use assets image files
import logo from '../../assets/images/logo.png';
import logo1 from '../../assets/images/logo1.png';

function SidebarSession({ menus, redirect, activeValue, openDropDown }: SideBarSessionInterFace) {
  const { Icon, RightIcon, label, active, children } = menus;
  return (
    <div className={`sidebar-menu-session ${activeValue === active ? 'active' : ''}`}>
      <div
        className={`main-menu ${activeValue === active ? 'active' : ''} `}
        onClick={() => openDropDown(active)}
        role="presentation">
        <div className="menu-icon">
          <Icon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menu-label">{label}</div>
        <div className="menu-dropdown-icon">
          <RightIcon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
      </div>
      <div className={`menu-dropdown ${activeValue === active ? 'active' : ''}`}>
        {children.map((menu: SubmenuInterFace) => (
          <div
            key={menu.url}
            className={`sub-menu ${window.location.pathname === menu.activeURL && 'active'}`}
            role="presentation"
            onClick={() => redirect(menu.url, active)}>
            {menu.label}
          </div>
        ))}
      </div>
      <div className={`tab-menu ${activeValue === active ? 'active' : ''}`}>
        <div className="menu-icon">
          <Icon color={activeValue === 'active' ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menus-list">
          <div className="menu-label">{label}</div>
          {children.map((menu: SubmenuInterFace) => (
            <div
              key={menu.url}
              className={`sub-menu ${window.location.pathname === menu.activeURL && 'active'}`}
              role="presentation"
              onClick={() => redirect(menu.url, active)}>
              {menu.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts.slice(0, 4).join('/');

  const sideBarMenu = [
    {
      icon: PersonIcon,
      rightIcon: DownArrowIcon,
      name: t('personnel'),
      activeURL: '/admin/personnel/personnel',
      children: [
        {
          label: t('person'),
          url: 'personnel/personnel/person',
          activeURL: '/admin/personnel/personnel/person',
        },
        {
          label: t('department'),
          url: 'personnel/personnel/department',
          activeURL: '/admin/personnel/personnel/department',
        },
        {
          label: t('position'),
          url: 'personnel/personnel/position',
          activeURL: '/admin/personnel/personnel/position',
        },
        {
          label: t('dismissedPersonnel'),
          url: 'personnel/personnel/dismissed-personnel',
          activeURL: '/admin/personnel/personnel/dismissed-personnel',
        },
        {
          label: t('pendingReview'),
          url: 'personnel/personnel/pending-review',
          activeURL: '/admin/personnel/personnel/pending-review',
        },
        {
          label: t('customAttributes'),
          url: 'personnel/personnel/custom-attributes',
          activeURL: '/admin/personnel/personnel/custom-attributes',
        },
        {
          label: t('listLibrary'),
          url: 'personnel/personnel/list-library',
          activeURL: '/admin/personnel/personnel/list-library',
        },
        {
          label: t('parameters'),
          url: 'personnel/personnel/parameters',
          activeURL: '/admin/personnel/personnel/parameters',
        },
      ],
    },
    {
      icon: CardHandIcon,
      rightIcon: DownArrowIcon,
      name: t('cardManagement'),
      activeURL: '/admin/personnel/card-management',
      children: [
        {
          label: t('card'),
          url: 'personnel/card-management/card',
          activeURL: '/admin/personnel/card-management/card',
        },
        {
          label: t('wiegandFormat'),
          url: 'personnel/card-management/wiegand-format',
          activeURL: '/admin/personnel/card-management/wiegand-format',
        },
        {
          label: t('issuedCardRecord'),
          url: 'personnel/card-management/issued-card-record',
          activeURL: '/admin/personnel/card-management/issued-card-record',
        },
      ],
    },
  ];

  const [activeValue, setActive] = useState(slicedString);

  const openDropDown = (key: string) => {
    setActive(key);
  };

  const redirect = (url: string): void => {
    navigate(url);
  };

  return (
    <div className="sidebar-container">
      <div className="header-logo">
        <div className="image">
          <img src={logo} alt="logo_image" />
        </div>
        <div className="image">
          <img src={logo1} alt="logo_image_1" />
        </div>
      </div>
      <div className="sidebar-content">
        <div className="sidebar-menus">
          {sideBarMenu.map((sidebar) => (
            <SidebarSession
              redirect={redirect}
              activeValue={activeValue}
              openDropDown={openDropDown}
              menus={{
                Icon: sidebar.icon,
                RightIcon: sidebar.rightIcon,
                label: sidebar.name,
                active: sidebar.activeURL,
                children: sidebar.children,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

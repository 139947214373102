import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use own components
import BatchIssueCard from './components/batch-issue-card';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ExportData from '../../shared-components/export-data/export-data';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

// Use assets json files
import CardManagementData from '../../assets/json/card-simple-data.json';

function CardManagement() {
  const { t } = useTranslation();
  const filterValue = {
    columnFilters: [],
    globalFilter: '',
    sorting: [],
  };
  const columnsField = [
    {
      accessorKey: 'cardNo',
      header: t('cardNumber'),
    },
    {
      accessorKey: 'personnelId',
      header: t('personnelId'),
      size: 150,
    },
    {
      accessorKey: 'firstName',
      header: t('firstName'),
      size: 150,
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
      size: 150,
    },
    {
      accessorKey: 'departmentNo',
      header: t('departmentNumber'),
      size: 150,
    },
    {
      accessorKey: 'departmentName',
      header: t('departmentName'),
    },
    {
      accessorKey: 'issuedCardDate',
      header: t('issuedCardDate'),
      size: 150,
    },
    {
      accessorKey: 'cardStatue',
      header: t('cardStatue'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('batchIssueCard'),
    content: <>hi</>,
  });

  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };

  const reported = () => {
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('batchIssueCard'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('batchIssueCard'),
          content: <BatchIssueCard closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('reportedLostCard'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('reported'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={reported}
              title={t('areYouWantToPerformTheReportedLostCardOperation')}
              buttonLabel={t('ok')}
            />
          ),
        });
      },
    },
    {
      title: t('reactivateLostCard'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('reactivate'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={reported}
              title={t('areYouWantToPerformTheReportedLostCardOperation')}
              buttonLabel={t('ok')}
            />
          ),
        });
      },
    },
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => console.log(filter);
  const fetchData = (): any => console.log('fes');
  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('card')}
        broadCram={[
          {
            title: t('cardManagement'),
          },
          {
            title: t('card'),
          },
        ]}
      />
      <CardBox
        title={t('card')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={filterValue}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          checkBoxActive
          fetchNextPage={fetchData}
          values={CardManagementData}
          fixedColumn={['cardNo']}
          totalRecords={100}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default CardManagement;

import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import AddLevelModal from './add-level-modal';
import ModalBox from '../../../shared-components/modal-box/modal-box';

// Use interface
import { PermissionInterface, Level } from '../../../interface/permissions.interface';

function PassageSetting({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();

  const [value, setValue] = useState({
    ...values,
    general: values.general ?? false,
    levelSettings: [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('Add to Levels'),
    content: <>hi</>,
  });
  const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const handleAddLevel = (newLevels: any[]) => {
    console.log('New Levels Added:', newLevels);
    setSelectedLevels(newLevels);
  };

  const checkboxTreeData = [
    { id: '1', label: t('Level 3'), value: 'my test', children: [] },
    { id: '2', label: t('My Testing'), value: '12 hours', children: [] },
    // Add other items as needed
  ];

  const checkboxData = selectedLevels.map((level) => ({
    label: level.levelName,
    value: level.id,
    id: level.id,
  }));

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="permissions-form">
        <div className="row-form step-4">
          <div className="item item1">
            <label htmlFor="level-settings" className="radio-label">
              {t('Level Settings')}
              <span>*</span>
            </label>
            <div className="level-settings-container">
              <div className="level-settings-header">
                <CheckBoxInputField
                  name="levelSettings"
                  aliment="column"
                  position="left"
                  value={
                    checkboxData.length > 0 && value.levelSettings.length === checkboxData.length
                      ? ['general']
                      : []
                  }
                  data={[{ label: 'General', id: 'general', value: 'general' }]}
                  onChangeFunction={(name: string, changeValue: any) => {
                    console.log(changeValue);
                    onChange(
                      name,
                      checkboxData.map((val) => val.value),
                    );
                  }}
                />
                <button
                  className="add-button"
                  type="button"
                  onClick={() => {
                    setModalOpen(true);
                    setModalView({
                      title: t('Add to Levels'),
                      content: (
                        <AddLevelModal
                          onClose={() => setModalOpen(false)}
                          onAddLevel={handleAddLevel}
                          checkboxTreeData={checkboxTreeData} // Pass the data here
                        />
                      ),
                    });
                  }}>
                  {t('Add')}
                </button>
              </div>
              <div className="level-settings-body">
                <CheckBoxInputField
                  label=""
                  name="levelSettings"
                  aliment="column"
                  position="left"
                  required
                  data={checkboxData}
                  value={value.levelSettings || []}
                  onChangeFunction={(name, changeValue) => {
                    onChange(name, changeValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="item">
            <RadioButtonField
              label={t('superUser')}
              name="superUser"
              aliment="row"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'yes',
                  value: 'yes',
                },
                {
                  label: t('no'),
                  id: 'no',
                  value: 'no',
                },
              ]}
              value={value.superUser}
              onChangeFunction={onChange}
            />
            <RadioButtonField
              label={t('deviceOperationRule')}
              name="deviceOperationRule"
              aliment="row"
              required
              data={[
                {
                  label: t('ordinaryUser'),
                  id: 'ordinaryUser',
                  value: 'ordinaryUser',
                },
                {
                  label: t('enroller'),
                  id: 'enroller',
                  value: 'enroller',
                },
                {
                  label: t('administrator'),
                  id: 'administrator',
                  value: 'administrator',
                },
              ]}
              value={value.deviceOperationRule}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassageSetting;

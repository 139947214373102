import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';

// Use Interface
import { CardBatchIssueCardInterFace } from '../../../interface/card.interface';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

function AddWiegandFormat({ closeModal }: CardBatchIssueCardInterFace) {
  const { t } = useTranslation();
  const [buttonDisable, setButtonDisable] = useState(true);

  const [wiegandFormatValue, setWiegandFormatValue] = useState({
    name: '',
    sideCode: '',
    totalBit: '',
    type: [],
    mode: 'one',
    firstPriorityCheck: '',
    secondPriorityCheck: '',
    cardCheckFormat: '',
    parityCheckFormat: '',
    parityCheckCode: [
      {
        title: t('oddParityCheck'),
        name: 'oddParityCheck',
        startBit: '',
        theMaximumLength: '',
      },
      {
        title: t('siteCodes'),
        name: 'siteCode',
        startBit: '',
        theMaximumLength: '',
      },
      {
        title: t('eventParityCheck'),
        name: 'eventParityCheck',
        startBit: '',
        theMaximumLength: '',
      },
      {
        title: t('manufacturerCode'),
        name: 'manufacturerCode',
        startBit: '',
        theMaximumLength: '',
      },
      {
        title: t('cid'),
        name: 'cid',
        startBit: '',
        theMaximumLength: '',
      },
    ],
  });

  const onChangeValue = (name: string, value: any) => {
    setWiegandFormatValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeMultiValue = (name: string, value: any, tagName: string) => {
    setWiegandFormatValue((prev: any) => {
      const parityCheckCode = prev.parityCheckCode.map((parity: any) => {
        const output = parity;
        if (parity.name === tagName) {
          output[name] = value;
        }
        return output;
      });
      return { ...prev, parityCheckCode: [...parityCheckCode] };
    });
  };

  useEffect(() => {
    console.log(wiegandFormatValue);
    if (
      wiegandFormatValue.name !== '' &&
      wiegandFormatValue.sideCode !== '' &&
      wiegandFormatValue.totalBit !== ''
    ) {
      if (
        wiegandFormatValue.mode === 'one' &&
        wiegandFormatValue.firstPriorityCheck !== '' &&
        wiegandFormatValue.secondPriorityCheck !== ''
      ) {
        wiegandFormatValue.parityCheckCode.forEach((parity) => {
          if (parity.startBit !== '' && parity.theMaximumLength !== '') {
            setButtonDisable(false);
          } else {
            setButtonDisable(true);
          }
        });
      } else if (
        wiegandFormatValue.mode === 'two' &&
        wiegandFormatValue.cardCheckFormat !== '' &&
        wiegandFormatValue.parityCheckFormat !== ''
      ) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    } else {
      setButtonDisable(true);
    }
  }, [wiegandFormatValue]);

  return (
    <div className="add-wiegand-format">
      <div className="add-wiegand-format-form">
        <div className="row-form">
          <div className="col">
            <InputField
              name="name"
              label={t('name')}
              id="name"
              required
              type="text"
              value={wiegandFormatValue.name}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="totalBit"
              label={t('totalBit')}
              id="totalBit"
              required
              type="text"
              value={wiegandFormatValue.totalBit}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="sideCode"
              label={t('sideCode')}
              id="sideCode"
              required
              type="text"
              value={wiegandFormatValue.sideCode}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col-1">
            <CheckBoxInputField
              name="type"
              data={[
                {
                  label: t('auto'),
                  id: 'auto',
                  value: 'auto',
                },
              ]}
              value={wiegandFormatValue.type}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
        <div className="row-form-radio">
          <div className="col">
            <RadioButtonField
              name="mode"
              data={[
                {
                  label: t('modeOne'),
                  id: 'one',
                  value: 'one',
                },
                {
                  label: t('modeTwo'),
                  id: 'two',
                  value: 'two',
                },
              ]}
              value={wiegandFormatValue.mode}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
        {wiegandFormatValue.mode === 'one' ? (
          <>
            <div className="row-form">
              <div className="row row-2">
                <InputField
                  name="firstPriorityCheck"
                  label={t('firstPriorityCheck')}
                  id="firstPriorityCheck"
                  type="text"
                  required
                  value={wiegandFormatValue.firstPriorityCheck}
                  onChangeFunction={onChangeValue}
                />
                <InputField
                  name="secondPriorityCheck"
                  label={t('secondPriorityCheck')}
                  id="secondPriorityCheck"
                  type="text"
                  required
                  value={wiegandFormatValue.secondPriorityCheck}
                  onChangeFunction={onChangeValue}
                />
              </div>
            </div>
            <div className="flex-warp">
              {wiegandFormatValue.parityCheckCode.map((parityCheck, index) => (
                <div className="warp-item">
                  <div className="title">{parityCheck.title}</div>
                  <div className="bit-value">
                    <InputField
                      name="startBit"
                      label={t('startBit')}
                      id={`startBit_${index}`}
                      type="text"
                      required
                      value={parityCheck.startBit}
                      onChangeFunction={(name, value) =>
                        onChangeMultiValue(name, value, parityCheck.name)
                      }
                    />
                    <InputField
                      name="theMaximumLength"
                      label={t('theMaximumLength')}
                      id={`theMaximumLength_${index}`}
                      type="text"
                      required
                      value={parityCheck.theMaximumLength}
                      onChangeFunction={(name, value) =>
                        onChangeMultiValue(name, value, parityCheck.name)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="row-form">
            <div className="col-0">
              <InputField
                name="cardCheckFormat"
                label={t('cardCheckFormat')}
                id="cardCheckFormat"
                type="text"
                required
                value={wiegandFormatValue.cardCheckFormat}
                onChangeFunction={onChangeValue}
              />
            </div>
            <div className="col-0">
              <InputField
                name="parityCheckFormat"
                label={t('parityCheckFormat')}
                id="parityCheckFormat"
                type="text"
                required
                value={wiegandFormatValue.parityCheckFormat}
                onChangeFunction={onChangeValue}
              />
            </div>
          </div>
        )}
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              console.log(wiegandFormatValue);
              closeModal();
            }}
            title={t('done')}
            disabled={buttonDisable}
            className="btn-primary btn-bold"
          />
        </div>
      </div>
    </div>
  );
}

export default AddWiegandFormat;

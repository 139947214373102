import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// Use all slices
import { personSlice } from './slices/PersonSlices';
import { notificationSlice } from './slices/NotificationSlices';
import { departmentSlice } from './slices/DepartmentSlices';
import { positionSlice } from './slices/PositionSlices';
import { dismissalPersonnelSlice } from './slices/DismissalPersonSlices';
import { librarySlice } from './slices/LibrarySlices';
import { customAttributesSlices } from './slices/CustomAttributesSlices';
import { pendingReviewSlices } from './slices/PendingReviewSlices';
import { parameterSlices } from './slices/ParameterSlices';

// store the all data
export const store = configureStore({
  reducer: {
    persons: personSlice.reducer,
    departments: departmentSlice.reducer,
    positions: positionSlice.reducer,
    notification: notificationSlice.reducer,
    dismissalPerson: dismissalPersonnelSlice.reducer,
    library: librarySlice.reducer,
    customAttribute: customAttributesSlices.reducer,
    pendingReviews: pendingReviewSlices.reducer,
    parameters: parameterSlices.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector = useSelector.withTypes<ReturnType<typeof store.getState>>();

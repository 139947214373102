import Cookies from 'universal-cookie';

export const getOicStorageKey = () => {
  const cookies = new Cookies();
  const authSettings = cookies.get('token');
  if (authSettings) {
    return authSettings;
  }
  return null;
};

export const getOicInfo = () => {
  const key = getOicStorageKey();
  if (key) {
    return key;
  }
  return null;
};

export const decodeJWTToken = (token: string) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
};

export const getToken = () => {
  const oic = getOicInfo();
  if (oic) {
    return {
      Authorization: `Bearer ${oic}`,
      // 'content-type': 'multipart/form-data',
    };
  }
  return {};
};

export const getFirstCapsLetter = (name: string = '') => {
  return name.toUpperCase().split('')[0];
};

export const changeDateTimeFormat = (value: any) => {
  return value
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(value))
    : '';
};

export const changeDateFormat = (value: any) => {
  const date = new Date(value);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};
export const getCurrentDateTimeWithTimezone = () => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Kolkata', // Specify your timezone
  };

  const formattedDate = new Date().toLocaleString('en-GB', options).match(/\d+/g);

  if (formattedDate && formattedDate.length === 6) {
    const [day, month, year, hour, minute, second] = formattedDate;
    return `${year}${month}${day}${hour}${minute}${second}`;
  }

  return '00000000000000';
};

export const fileDownload = (data: any, name: string) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name); // Set file name
  document.body.appendChild(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(url);
  // Clean up the Object URL to release memory
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};

import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { ParameterInterface } from '../../../interface/parameter.interface';

// Use interface

function CardSetting({ onChangeFunction, values }: ParameterInterface) {
  const { t } = useTranslation();
  const [value, setValue] = useState(values);

  const onChagne = (name: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  return (
    <div className="personnel-setting">
      <div className="personnel-setting-form">
        <div className="column">
          <div className="item item-1">
            <RadioButtonField
              label={t('cardFormatDisplay')}
              name="pers.cardHex"
              aliment="row"
              required
              data={[
                {
                  label: t('decimal'),
                  id: '0',
                  value: '0',
                },
                {
                  label: t('hexadecimal'),
                  id: '1',
                  value: '1',
                },
              ]}
              value={value['pers.cardHex']}
              onChangeFunction={onChagne}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('multipleCardPerPerson')}
              name="pers.cardsSupport"
              aliment="row"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'true',
                  value: 'true',
                },
                {
                  label: t('no'),
                  id: 'false',
                  value: 'false',
                },
              ]}
              value={value['pers.cardsSupport']}
              onChangeFunction={onChagne}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('cardReadingMode')}
              name="pers.cardsReadMode"
              aliment="column"
              required
              data={[
                {
                  label: t('readByController'),
                  id: '1',
                  value: '1',
                },
              ]}
              value={value['pers.cardsReadMode']}
              onChangeFunction={onChagne}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSetting;

import React from 'react';

// Use common modules
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { Provider } from 'react-redux';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Use own componenta
import App from './App';
import reportWebVitals from './reportWebVitals';

// Use store data
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
);

reportWebVitals();

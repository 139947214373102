import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Table from '../../../shared-components/table/table';

// Use Interface
import { CardBatchIssueCardInterFace } from '../../../interface/card.interface';

// Use assets svg icons
import { Search } from '../../../assets/svgicons/svgicon';

function BatchIssueCard({ closeModal }: CardBatchIssueCardInterFace) {
  const { t } = useTranslation();
  const selectData = [
    {
      label: 'Value 1',
      id: 'value1',
    },
    {
      label: 'Value 2',
      id: 'value2',
    },
    {
      label: 'Value 3',
      id: 'value3',
    },
    {
      label: 'Value 4',
      id: 'value4',
    },
  ];

  const [departmentValue, setDepartmentValue] = useState({
    search: '',
    inputCardNo: '',
    cardEnrollmentMethod: '',
  });

  const onChangeValue = (name: string, value: any) => {
    setDepartmentValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="bitch-issue-card">
      <div className="bitch-issue-card-form">
        <div className="row-form">
          <div className="col">
            <InputField
              name="search"
              label={t('search')}
              id="search"
              type="text"
              value={departmentValue.search}
              innerIconStatus
              InnerIcon={Search}
              innerIconFunction={() => {}}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="inputCardNo"
              label={t('inputCardNo')}
              id="inputCardNo"
              type="text"
              value={departmentValue.inputCardNo}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col-text">
            <div className="link-tag">{t('issueCard')}</div>
          </div>
          <div className="col">
            <SelectBoxField
              name="cardEnrollmentMethod"
              label={t('cardEnrollmentMethod')}
              id="cardEnrollmentMethod"
              data={selectData}
              value={departmentValue.cardEnrollmentMethod}
              className=""
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
        </div>
        <div className="row row-2">
          <div className="col">
            <Table
              header={[
                {
                  label: t('personnelId'),
                  id: 'personnelId',
                },
                {
                  label: t('firstName'),
                  id: 'firstName',
                },
                {
                  label: t('lastName'),
                  id: 'lastName',
                },
                {
                  label: t('departmentNumber'),
                  id: 'departmentNo',
                },
              ]}
              value={[
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
              ]}
            />
          </div>
          <div className="col">
            <Table
              header={[
                {
                  label: t('personnelId'),
                  id: 'personnelId',
                },
                {
                  label: t('firstName'),
                  id: 'firstName',
                },
                {
                  label: t('lastName'),
                  id: 'lastName',
                },
                {
                  label: t('departmentNumber'),
                  id: 'departmentNo',
                },
              ]}
              value={[
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
                {
                  personnelId: '215',
                  firstName: 'Mani',
                  lastName: 'Raja',
                  departmentNo: '2342',
                  id: 1,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              console.log(departmentValue);
              closeModal();
            }}
            title={t('done')}
            className="btn-primary btn-bold"
          />
        </div>
      </div>
    </div>
  );
}

export default BatchIssueCard;

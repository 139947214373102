import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use Interface
import { CardBatchIssueCardInterFace } from '../../../interface/card.interface';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

function BatchIssueCard({ closeModal }: CardBatchIssueCardInterFace) {
  const { t } = useTranslation();
  const selectData = [
    {
      label: 'Main door power',
      id: 'value1',
    },
    {
      label: 'Entry',
      id: 'value2',
    },
    {
      label: '192.165.10.179',
      id: 'value3',
    },
    {
      label: 'Out',
      id: 'value4',
    },
  ];

  const [departmentValue, setDepartmentValue] = useState({
    device: '',
    autoCalculate: [],
    cardEnrollmentMethod: '',
    cardFormat: '',
    oddEventParityFormat: '',
    cardsList: [
      {
        title: t('card1'),
        name: 'card1',
        siteCode: '',
        cardNumber: '',
        originalCardNumber: '',
      },
      {
        title: t('card2'),
        name: 'card2',
        siteCode: '',
        cardNumber: '',
        originalCardNumber: '',
      },
      {
        title: t('card3'),
        name: 'card3',
        siteCode: '',
        cardNumber: '',
        originalCardNumber: '',
      },
    ],
  });

  const onChangeValue = (name: string, value: any) => {
    setDepartmentValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="card-format-testing">
      <div className="card-format-testing-form">
        <div className="row-form">
          <div className="col flex-1">
            <SelectBoxField
              name="dismissalType"
              label={t('dismissalType')}
              id="dismissalType"
              data={selectData}
              value={departmentValue.device}
              className=""
              required
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col">
            <Button onClickFunction={() => {}} className="btn-primary" title={t('readCard')} />
          </div>
          <div className="col">
            <Button
              onClickFunction={() => {}}
              className="btn-primary"
              title={t('clearCardInformation')}
            />
          </div>
        </div>
        <div className="column-form">
          {departmentValue.cardsList.map((val, index) => (
            <div className="col">
              <div className="title">{val.title}</div>
              <div className="rows">
                <InputField
                  name="siteCode"
                  label={t('siteCode')}
                  id={`siteCode_${index}`}
                  type="text"
                  required
                  value={val.siteCode}
                  onChangeFunction={onChangeValue}
                />
                <InputField
                  name="cardNumber"
                  label={t('cardNumber')}
                  id={`cardNumber_${index}`}
                  type="text"
                  required
                  value={val.cardNumber}
                  onChangeFunction={onChangeValue}
                />
                <InputField
                  name="originalCardNumber"
                  label={t('originalCardNumber')}
                  id={`originalCardNumber_${index}`}
                  type="text"
                  required
                  value={val.originalCardNumber}
                  onChangeFunction={onChangeValue}
                />
              </div>
            </div>
          ))}
        </div>
        <p className="text-danger">
          {/* Note: When you continue to read a new card, please manually switch to the next card. */}
          {t('noteWhenYouContinueToReadANewCardPleaseManuallySwitchToTheNextCard')}
        </p>
        <div className="row-form">
          <div className="col">
            <Button
              onClickFunction={() => {}}
              className="btn-primary"
              disabled
              title={t('recommendedCardFormat')}
            />
          </div>
          <div className="col">
            <CheckBoxInputField
              name="autoCalculate"
              data={[
                {
                  label: t('autoCalculateSiteCodeWhileTheSiteCodeIsLeftBlank'),
                  id: 'yes',
                  value: 'yes',
                },
              ]}
              value={departmentValue.autoCalculate}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
        <div className="column-form">
          <InputField
            name="cardFormat"
            label={t('cardFormat')}
            id="cardFormat"
            type="text"
            required
            value={departmentValue.cardFormat}
            onChangeFunction={onChangeValue}
          />
          <InputField
            name="oddEventParityFormat"
            label={t('oddEventParityFormat')}
            id="oddEventParityFormat"
            type="text"
            required
            value={departmentValue.oddEventParityFormat}
            onChangeFunction={onChangeValue}
          />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              console.log(departmentValue);
              closeModal();
            }}
            title={t('done')}
            className="btn-primary btn-bold"
          />
        </div>
      </div>
    </div>
  );
}

export default BatchIssueCard;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import MultipleImageUpload from '../../../shared-components/multiple-file-upload/multiple-file-upload';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

// Import the API module for importing personnel photos
import PersonalApis from '../../../utils/api/person';

// Import the notification action
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function ImportPersonnelPhoto({
  closeModal,
  submitFunction,
}: {
  closeModal: () => void;
  submitFunction: (val: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState({
    fileType: '',
    fileList: [],
    importPhoto: '',
    importCropFace: '',
  });
  const [disableButton, setDisableButton] = useState(true);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const handleSubmit = () => {
    const formData = new FormData();

    // Append the file, fileType, importPhoto, and importCropFace to the formData
    if (value.fileList.length > 0) {
      formData.append('personPhotos', value.fileList[0]); // Append the first file, adjust if multiple files are required
    }
    formData.append('fileType', value.fileType);
    formData.append('importPhoto', value.importPhoto === 'true' ? '1' : '0');
    formData.append('importCropFace', value.importCropFace === 'true' ? '1' : '0');

    PersonalApis.importPersPhoto(formData)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personPhotosImportedSuccessfully'),
              status: 200, // Success status code
            }),
          );
          closeModal();
          setDisableButton(false);
          submitFunction(formData); // Pass the request object or another relevant value
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    // Enable the button if all fields are filled
    if (value.fileType && value.fileList.length > 0 && value.importPhoto && value.importCropFace) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);

  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="row row-1">
          <div className="col">
            <SelectBoxField
              name="fileType"
              label={t('fileType')}
              id="fileType"
              value={value.fileType}
              required
              onChangeFunction={(name, changeValue) => onChange(name, changeValue.id)}
              data={[
                { label: 'PNG', id: 'png' },
                { label: 'JPG', id: 'jpg' },
              ]}
              className=""
            />
          </div>
        </div>
        <div className="row row-2">
          <div className="col">
            <RadioButtonField
              label={t('Import Photo')}
              name="importPhoto"
              aliment="column"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'true',
                  value: 'true',
                },
                {
                  label: t('no'),
                  id: 'false',
                  value: 'false',
                },
              ]}
              value={value.importPhoto}
              onChangeFunction={onChange}
            />
          </div>
          <div className="col">
            <RadioButtonField
              label={t('Import Crop Face')}
              name="importCropFace"
              aliment="column"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'true',
                  value: 'true',
                },
                {
                  label: t('no'),
                  id: 'false',
                  value: 'false',
                },
              ]}
              value={value.importCropFace}
              onChangeFunction={onChange}
            />
          </div>
        </div>
        <div className="item">
          <MultipleImageUpload
            title={t('uploadFile')}
            required
            name="fileList"
            value={value.fileList}
            onClick={(name, changeValue) => {
              onChange(name, changeValue);
            }}
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={handleSubmit}
              title={t('import')}
              disabled={disableButton}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportPersonnelPhoto;

import { fetch, post } from './base/index';

export default {
  getDepartment: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/department/getDeptList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  getAllDepartment: () => {
    return post(`${process.env.REACT_APP_API_URL}api/department/getAllDepartments`, {});
  },
  addDepartment: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}api/department/add`, { ...data });
  },
  deleteDepartment: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}api/department/delete/${id}`, {});
  },
  importDepartment: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}api/department/importDepartment`, data);
  },
  dlDprtmntImprtTemplate: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}api/department/dlDprtmntImprtTemplate`,
      'POST',
      data,
    );
  },
  exportDepartment: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}api/department/exportDepartment`, 'POST', data);
  },
};

import React from 'react';

// Use interface
import { CheckBoxInputFieldInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { CheckBoxIcon, CheckBoxActiveIcon } from '../../assets/svgicons/svgicon';

function CheckBoxInputField({
  name,
  data,
  value = [], // Default to empty array if value is not provided
  label,
  aliment,
  position,
  required,
  onChangeFunction,
}: CheckBoxInputFieldInterFace) {
  const onChange = (selectName: string, selectValue: string | number): void => {
    let changeValue: any = value;
    if (changeValue.includes(selectValue)) {
      changeValue = changeValue.filter((val: any) => val !== selectValue);
      onChangeFunction(selectName, changeValue);
    } else {
      changeValue.push(selectValue);
      onChangeFunction(selectName, changeValue);
    }
  };
  return (
    <div className="checkbox-form-item">
      {label && (
        <label htmlFor={label} className="checkbox-label">
          {label}
          {required && <span>*</span>}
        </label>
      )}

      <div className={`checkbox-group-list ${aliment}`}>
        {data.map((checkBox) => (
          <div
            key={checkBox.value} // Add a unique key prop
            className={`checkbox-field ${position} ${checkBox.disabled}`}
            onClick={() => checkBox.disabled !== 'disabled' && onChange(name, checkBox.value)}
            role="presentation">
            <div className="checkbox-icon">
              {value.includes(checkBox.value) ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
            </div>
            <div className="checkbox-title">{checkBox.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CheckBoxInputField;

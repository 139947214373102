import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import StatisticsModal from './components/statistics';
import ChangeDepartment from './components/change-department';
import ChangePosition from './components/change-position';
import Dismissal from './components/dismissal';
import ExportBiometricTemplate from './components/export-biometric-template';
import ExportDismissal from './components/export-dismissal';
import ImportPersonnel from './components/import-personnel';
import ImportBiometricTemplate from './components/import-biometric-template';
import ImportPersonnelPhoto from './components/import-personnel-photo';
import ImportDismissions from './components/import-dismission';
import DownloadPersonnelImportTemplate from './components/download-personnel-import-template';
import PrintCard from './components/print-card';

// Use api's calls
import personApis from '../../utils/api/person';

// Use redux functions
import {
  fetchPersonsList,
  getSinglePerson,
  setSearchFilter,
  removeSinglePerson,
  deletePerson,
} from '../../redux/slices/PersonSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { fetchDismissalPersonsList } from '../../redux/slices/DismissalPersonSlices';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ExportData from '../../shared-components/export-data/export-data';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import TabModalBox from '../../shared-components/tab-modal-box/tab-modal-box';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import ImportData from '../../shared-components/import-data/import-data';

// Use assets svg icons
import { AddIcon, CheckCircleIcon, CloseCircleIcon } from '../../assets/svgicons/svgicon';

// Use interface
import { PersonValue } from '../../interface/personnel.interface';

function Person() {
  const { t } = useTranslation();
  let sortTime = true;
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const { persons, status, totalDataCount, currentPageNo, searchFilter } = useAppSelector(
    (state: any) => state.persons,
  );
  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
      enableHiding: false,
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
      enableHiding: false,
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
      enableHiding: false,
    },
    {
      accessorKey: 'enabledCredential',
      header: t('enable'),
      visibleInShowHideMenu: false,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'appAuthorization',
      header: t('appLogin'),
      visibleInShowHideMenu: false,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
      enableHiding: false,
    },
    {
      accessorKey: 'deptCode',
      header: t('departmentNumber'),
      enableHiding: false,
    },
    {
      accessorKey: 'verifyMode',
      header: t('verificationMode'),
    },
    {
      accessorKey: 'createTime',
      header: t('createTime'),
    },
    {
      accessorKey: 'gender',
      header: t('gender'),
    },
    {
      accessorKey: 'birthday',
      header: t('birthday'),
    },
    {
      accessorKey: 'mobilePhone',
      header: t('mobilePhone'),
    },
    {
      accessorKey: 'cardNumber',
      header: t('cardNumber'),
    },
    {
      accessorKey: 'licensePlate',
      header: t('licensePlate'),
    },
    {
      accessorKey: 'email',
      header: t('email'),
    },
    {
      accessorKey: 'certType',
      header: t('certificateType'),
    },
    {
      accessorKey: 'certNumber',
      header: t('certificateNumber'),
    },
    {
      accessorKey: 'positionName',
      header: t('positionName'),
    },
    {
      accessorKey: 'position',
      header: t('positionNumber'),
    },
    {
      accessorKey: 'hireDate',
      header: t('hireDate'),
    },
    {
      accessorKey: 'buildingName',
      header: t('buildingName'),
    },
    {
      accessorKey: 'unitName',
      header: t('unitName'),
    },
    {
      accessorKey: 'roomNumber',
      header: t('roomNumber'),
    },
    {
      accessorKey: 'verification',
      header: t('verification'),
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<string[]>([]);
  const [modalView, setModalView] = useState({
    title: t('addNewPerson'),
    content: <>hi</>,
  });
  const [tabModalView, setTabModalView] = useState({
    title: '',
    content: [
      {
        title: '',
        key: '',
        content: <>hi</>,
      },
    ],
    index: 0,
  });

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchPersonsList({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    personApis
      .deletePerson(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePerson(id));
          setModalOpen(false);
          fetchData({ pageNo: 1 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => {
        dispatch(getSinglePerson(val.id));
        navigator(`edit/${val.id}`);
      },
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };

  const selfLoginPassword = () => {
    const personIds = persons
      .filter((val: PersonValue) => selectedPerson.includes(val.id))
      .map((val: PersonValue) => val.person_id);
    const idsArray = personIds;
    const request = idsArray.join('&ids=');
    personApis
      .resetSelfPwd(request)
      .then((response) => {
        if (response.data.success) {
          // Handle successful response
          dispatch(
            setNotificationError({
              error: t('resetSelfloginPasswordUpdatedSuccessfully'),
              status: 200, // Success status code
            }),
          );
          setModalOpen(false);
          setSelectedPerson([]);
        } else {
          // Handle specific error response
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500, // Fallback to 500 if status is not available
          }),
        );
        setSelectedPerson([]);
      });
  };
  const submitDismissalFunction = () => {
    dispatch(
      setNotificationError({
        error: t('dismissalPersonnelAddedSuccessfully'),
        status: 200,
      }),
    );
    fetchData({ pageNo: 1 });
    setSelectedPerson([]);
    dispatch(fetchDismissalPersonsList({ pageNo: 1 }));
    setTabModalOpen(false);
  };
  const submitChangeDepartmentFunction = () => {
    dispatch(
      setNotificationError({
        error: t('departmentChangedSuccessfully'),
        status: 200,
      }),
    );
    fetchData({ pageNo: 1 });
    setSelectedPerson([]);
    setTabModalOpen(false);
  };
  const submitChangePositionFunction = () => {
    dispatch(
      setNotificationError({
        error: t('positionChangedSuccessfully'),
        status: 200,
      }),
    );
    fetchData({ pageNo: 1 });
    setSelectedPerson([]);
    setTabModalOpen(false);
  };

  const submitPrintCard = (data: any) => {
    console.log(data);
  };

  const enableDisablePersons = (data: string) => {
    const personIds = persons
      .filter((val: PersonValue) => selectedPerson.includes(val.id))
      .map((val: PersonValue) => val.person_id);
    const request = personIds.join('&ids=');
    let apiCall;
    let message: string;
    if (data === 'disable') {
      apiCall = personApis.disablePersons(request);
      message = t('personDisabledSuccessfully');
    } else if (data === 'enable') {
      apiCall = personApis.enablePersons(request);
      message = t('personEnabledSuccessfully');
    } else if (data === 'app-login-enable') {
      apiCall = personApis.enableAppLogin(request);
      message = t('personAppLoginEnabledSuccessfully');
    } else {
      apiCall = personApis.disableAppLogin(request);
      message = t('personAppLoginDisableSuccessfully');
    }
    apiCall
      .then((response) => {
        if (response.data.success) {
          // Handle successful response
          dispatch(
            setNotificationError({
              error: message,
              status: 200, // Success status code
            }),
          );
          fetchData({ pageNo: 1 });
          setModalOpen(false);
          setSelectedPerson([]);
        } else {
          // Handle specific error response
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500, // Fallback to 500 if status is not available
          }),
        );
      });
  };

  const moreActionButton = [
    {
      title: t('personalAdjustment'),
      clickFunction: (): void => {
        setTabModalView({
          title: t('personalAdjustment'),
          content: [
            {
              title: t('changeDepartment'),
              content: (
                <ChangeDepartment
                  closeModal={() => setTabModalOpen(false)}
                  selectedPerson={selectedPerson}
                  submitFunction={submitChangeDepartmentFunction}
                />
              ),
              key: 'changeDepartment',
            },
            {
              title: t('changePosition'),
              content: (
                <ChangePosition
                  closeModal={() => setTabModalOpen(false)}
                  selectedPerson={selectedPerson}
                  submitFunction={submitChangePositionFunction} // Pass the submit function here
                />
              ),
              key: 'changePosition',
            },
            {
              title: t('dismissal'),
              content: (
                <Dismissal
                  closeModal={() => setTabModalOpen(false)}
                  selectedPerson={selectedPerson}
                  submitFunction={submitDismissalFunction}
                />
              ),
              key: 'dismissal',
            },
          ],
          index: 0,
        });
        setTabModalOpen(true);
      },
    },
    {
      title: t('export'),
      clickFunction: (): void => {
        setTabModalView({
          title: t('export'),
          content: [
            {
              title: t('exportPersonnel'),
              content: (
                <ExportData closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />
              ),
              key: 'exportPersonnel',
            },
            {
              title: t('exportBiometricTemplate'),
              content: (
                <ExportBiometricTemplate
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'exportBiometricTemplate',
            },
            {
              title: t('exportPersonnelPhoto'),
              content: (
                <ExportDismissal
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'dismissal',
            },
          ],
          index: 0,
        });
        setTabModalOpen(true);
      },
    },
    {
      title: t('import'),
      clickFunction: (): void => {
        setTabModalView({
          title: t('import'),
          content: [
            {
              title: t('importPersonnel'),
              content: (
                <ImportPersonnel
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'importPersonnel',
            },
            {
              title: t('importBiometricTemplate'),
              content: (
                <ImportBiometricTemplate
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'importBiometricTemplate',
            },
            {
              title: t('importPersonnelPhoto'),
              content: (
                <ImportPersonnelPhoto
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'importPersonnelPhoto',
            },
            {
              title: t('importDismissions'),
              content: (
                <ImportDismissions
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'importDismissions',
            },
            {
              title: t('downloadPersonnelImportTemplate'),
              content: (
                <DownloadPersonnelImportTemplate
                  closeModal={() => setTabModalOpen(false)}
                  submitFunction={exportData}
                />
              ),
              key: 'downloadPersonnelImportTemplate',
            },
            {
              title: t('downloadDismissionImportTemplate'),
              content: (
                <ImportData closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />
              ),
              key: 'downloadDismissionImportTemplate',
            },
          ],
          index: 0,
        });
        setTabModalOpen(true);
      },
    },
    {
      title: t('statistics'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('statistics'),
          content: <StatisticsModal closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('printCard'),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('printCard'),
            content: (
              <PrintCard
                closeModal={() => setModalOpen(false)}
                personIds={selectedPerson}
                submitFunction={submitPrintCard}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('enablePersons'),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('enablePersons'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToEnableThisPersons')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('enable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('disablePersons'),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('disablePersons'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToDisableThisPersons')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('disable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('enableAppLogin'),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('enableAppLogin'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToEnableThisPersonAppLogin')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('app-login-enable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('disableAppLogin'),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('disableAppLogin'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToDisableThisPersonsAppLogin')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('app-login-disable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('resetSelfLoginPassword'),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('resetSelfLoginPassword'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToResetSelfLoginPassword')}
                buttonLabel={t('ok')}
                okFunction={selfLoginPassword}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    dispatch(removeSinglePerson());
    if (persons.length === 0 && sortTime) {
      sortTime = false;
      fetchData({ pageNo: 1 });
    }
  }, []);
  return (
    <div className="person">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TabModalBox
        status={tabModalOpen}
        closeModal={() => {
          setTabModalOpen(false);
          setTabModalView({
            title: '',
            content: [
              {
                title: '',
                key: '',
                content: <>hi</>,
              },
            ],
            index: 0,
          });
        }}
        title={tabModalView.title}
        tabs={tabModalView.content}
        currentIndex={tabModalView.index}
      />
      <TopHeader
        title={t('person')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('person'),
          },
        ]}
      />
      <CardBox
        title={t('listOfPerson')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              navigator('add');
            }}
          />
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedPerson}
          getSelectedData={(val: any): any => {
            setSelectedPerson(val);
          }}
          checkBoxActive
          fetchNextPage={fetchNextData}
          values={persons}
          fixedColumn={['pin']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Person;

import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

import PersonalApis from '../../utils/api/person';
// Use shared components
import InputField from '../input-field/input-field';
import Button from '../button/button';
import SelectBoxField from '../selectbox-field/selectbox-field';
import RadioButtonField from '../radio-button-field/radio-button-field';

// Use interface
import { ExportDataInterFace } from '../../interface/common.interface';

//  Import the helper function
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../utils/helpers'; // Path based on your project structure
// Use assets svg icons
import { EyeCloseIcon, EyeOpenIcon } from '../../assets/svgicons/svgicon';
import { useAppDispatch } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices'; // Import the notification action

function ExportData({ closeModal }: ExportDataInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(true);
  const [submitButton] = useState(false);
  const [type, setType] = useState('password');
  const [exportValue, setExportValue] = useState({
    fileType: '',
    exportType: 'all',
    startPosition: '',
    endPosition: '',
    fileEncryption: 'no',
    password: '',
  });

  const onChangeValue = (name: string, value: any) => {
    setExportValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    let i = 0;
    if (exportValue.fileType === '' || exportValue.exportType === '') {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [exportValue]);
  // API Integration
  const submit = () => {
    setDisableButton(true);

    // Create a FormData object
    const payload = {
      attrs: [
        'Personnel+ID-pin',
        'First+Name-name',
        'Last+Name-lastName',
        'Department+Number-deptCode',
        'Department+Name-deptName',
      ],
    };

    // Create a new FormData object
    const formData = new FormData();

    // Append the attrs array to the FormData
    payload.attrs.forEach((attr) => {
      formData.append('attrs', attr);
    });
    formData.append('isEncrypt', exportValue.fileEncryption === 'yes' ? '1' : '0');
    formData.append('reportType', exportValue.fileType.toUpperCase());

    // Call the API using formData
    PersonalApis.exportPerson(formData)
      .then((response) => response.blob()) // Set responseType to 'blob'
      .then((data) => {
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(data, `person_${currentDateTime}.xls`);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setDisableButton(false);
      });
  };

  return (
    <div className="export-data">
      <div className="row row-4">
        <div className="col">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={exportValue.fileType}
            required
            errorStatus={exportValue.fileType === '' && submitButton}
            message={exportValue.fileType === '' ? 'File Type is required' : ''}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <RadioButtonField
            label={t('dataToExport')}
            name="exportType"
            aliment="column"
            required
            data={[
              { label: t('all'), id: 'all', value: 'all' },
              { label: t('selected'), id: 'selected', value: 'selected' },
            ]}
            value={exportValue.exportType}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <InputField
            name="startPosition"
            label={t('startPosition')}
            id="startPosition"
            type="text"
            value={exportValue.startPosition}
            disabled={exportValue.exportType === 'all'}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <InputField
            name="endPosition"
            label={t('endPosition')}
            id="endPosition"
            type="text"
            value={exportValue.endPosition}
            disabled={exportValue.exportType === 'all'}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <RadioButtonField
            label={t('fileEncryption')}
            name="fileEncryption"
            aliment="column"
            required
            data={[
              { label: t('yes'), id: 'yes', value: 'yes' },
              { label: t('no'), id: 'no', value: 'no' },
            ]}
            value={exportValue.fileEncryption}
            onChangeFunction={onChangeValue}
          />
        </div>
        {exportValue.fileEncryption === 'yes' && (
          <div className="col">
            <InputField
              name="password"
              label={t('password')}
              id="password"
              type={type}
              value={exportValue.password}
              innerIconStatus
              InnerIcon={type === 'password' ? EyeCloseIcon : EyeOpenIcon}
              innerIconFunction={() => setType(type === 'password' ? 'text' : 'password')}
              onChangeFunction={onChangeValue}
            />
          </div>
        )}
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('back')} className="btn-default btn-bold" />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ExportData;

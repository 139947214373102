import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import Button from '../../../shared-components/button/button';

// Use interface
import { PrintCardInterFace } from '../../../interface/personnel.interface';

// Use assets images
import CardFrond from '../../../assets/images/card-frond.png';
import CardBack from '../../../assets/images/card-back.png';
import Table from '../../../shared-components/table/table';

// Use Redux functions
import { fetchCardTemplates } from '../../../redux/slices/PersonSlices';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

// Use api functions
import personApis from '../../../utils/api/person';

function PrintCard({ closeModal, submitFunction, personIds }: PrintCardInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { persons, cardTemplates } = useAppSelector((state: any) => state.persons);
  const personList = persons.filter((val: any) => personIds.includes(val.id));

  const [disableButton, setDisableButton] = useState(true);
  const [exportValue, setExportValue] = useState({
    cardTemplate: '',
    printer: '',
    printOption: '',
    opposite: CardBack,
    front: CardFrond,
    personnel: personList.map((val: any) => val.person_id),
  });

  const onChangeValue = (name: string, value: any) => {
    setExportValue((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'cardTemplate') {
      personApis
        .getCardTemplate({
          personId: exportValue.personnel[0],
          templateId: value,
        })
        .then((response) => {
          setExportValue((prev) => ({
            ...prev,
            opposite: `data:image/png;base64,${response.data.data.opposite}`,
            front: `data:image/png;base64,${response.data.data.front}`,
          }));
        })
        .catch((error) => {
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.response?.status || 500, // Fallback to 500 if status is not available
            }),
          );
        });
    }
  };

  useEffect(() => {
    let i = 0;
    if (
      exportValue.cardTemplate === '' ||
      exportValue.printer === '' ||
      exportValue.printOption === ''
    ) {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [exportValue]);

  useEffect(() => {
    if (cardTemplates.length === 0) {
      dispatch(fetchCardTemplates({}));
    }
  }, []);
  return (
    <div className="modal-page">
      <div className="statistics-page">
        <div className="row">
          <SelectBoxField
            name="cardTemplate"
            label={t('cardTemplate')}
            id="cardTemplate"
            value={exportValue.cardTemplate}
            required
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={cardTemplates}
            className=""
          />
        </div>
        <div className="row">
          <SelectBoxField
            name="printer"
            label={t('printer')}
            id="printer"
            value={exportValue.printer}
            required
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              {
                label: t('excel'),
                id: 'excel',
              },
              {
                label: t('pdf'),
                id: 'pdf',
              },
              {
                label: t('csv'),
                id: 'csv',
              },
              {
                label: t('txt'),
                id: 'txt',
              },
            ]}
            className=""
          />
        </div>
        <div className="row">
          <RadioButtonField
            label={t('printOption')}
            name="printOption"
            aliment="row"
            required
            data={[
              {
                label: t('frontSide'),
                id: 'frontSide',
                value: 'frontSide',
              },
              {
                label: t('bothSide'),
                id: 'bothSide',
                value: 'bothSide',
              },
            ]}
            value={exportValue.printOption}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="row" />
        <div className="row span-2">
          <div className="card-images">
            <div className="image">
              <img src={exportValue.front || CardFrond} alt="" />
            </div>
            <div className="image">
              <img src={exportValue.opposite || CardBack} alt="" />
            </div>
          </div>
        </div>
        <div className="col span-2">
          <Table
            checkBoxStatus
            header={[
              { label: t('personnelId'), id: 'id' },
              { label: t('firstName'), id: 'name' },
              { label: t('departmentName'), id: 'deptName' },
              { label: t('printStatus'), id: 'printStatus' },
            ]}
            value={personList}
            returnKey="person_id"
            selectedValues={exportValue.personnel}
            onSelectData={(val) => onChangeValue('personnel', val)}
          />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              submitFunction(exportValue);
            }}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default PrintCard;

// Use interface
import { ButtonInterFace } from '../../interface/common.interface';

function Button({ title, onClickFunction, disabled, className, icon }: ButtonInterFace) {
  return (
    <div className="button-field">
      <button
        type="button"
        className={`btn ${className}`}
        onClick={onClickFunction}
        disabled={disabled}>
        {title} {icon}
      </button>
    </div>
  );
}

export default Button;

import React, { useEffect, useState } from 'react';
// Use translation
import { useTranslation } from 'react-i18next';

// Use common modules
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use own component pages
import PersonalDetails from './personal-details';
import ProfessionalDetails from './professional-details';
import Permissions from './permissions';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import WidgetForm from '../../../shared-components/widget-forms/widget-forms';
import Button from '../../../shared-components/button/button';

// Use api components
import personAddApi from '../../../utils/api/person';

// Use validation functions
// import { emailValidation, phoneNumberValidation } from '../../../shared-components/form/validation';

// Use interface
import { PersonValue } from '../../../interface/personnel.interface';

// Use redux functions
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { addPerson } from '../../../redux/slices/PersonSlices';
import { fetchAllDepartmentList } from '../../../redux/slices/DepartmentSlices';
import { fetchAllPositionList } from '../../../redux/slices/PositionSlices';

function AddPerson() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams(); // Get the ID from the URL
  const allDepartments = useAppSelector((state: any) => state.departments.allDepartments);
  const allPositions = useAppSelector((state: any) => state.positions.allPositions); // Get all positions
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView] = useState({
    title: t('addNewPerson'),
    content: <>hi</>,
  });

  const [personValue, setPersonValue] = useState({
    id: '',
    levelName: '',
    timeZone: '',
    levelSettings: [],
    name: '',
    email: '',
    birthday: '',
    personPhoto: '',
    lastName: '',
    whatsappMobileNo: '',
    gender: '',
    mobilePhone: '',
    pin: '',
    hireDate: '',
    certNumber: '',
    deptCode: '',
    cardNo: '',
    superUser: '',
    certType: '',
    position: '',
    deviceVerification: '',
    biometrics: '',
    deviceOperationRule: '',
    type: [],
    attendanceMode: '',
    verificationRole: '',
    deviceOperationRole: '',
    personnelType: '',
    general: false,
    lockerNo: '',
    plateRegisterNo: [
      { licensePlate: '', parkingNo: '' },
      { licensePlate: '', parkingNo: '' },
    ],
    moreCards: ['', ''],
    booking: '',
    civilIdNo: '',
    maritalStatus: '',
    usufructo: '',
    passage: '',
    faceKioskArea: { areaName: [], hallWayArea: [] },
    attendanceArea: { areaName: [], hallWayArea: [] },
  });
  const onChangeFunction = (values: Partial<PersonValue>) => {
    setPersonValue((prev: any) => ({
      ...prev,
      ...values,
    }));
  };

  const submit = () => {
    const request = {
      birthday: personValue?.birthday,
      cardNo: personValue?.cardNo,
      name: personValue?.name,
      lastName: personValue?.lastName,
      email: personValue?.email,
      mobilePhone: personValue?.mobilePhone,
      gender: personValue?.gender,
      personPhoto: personValue?.personPhoto?.replace(/^data:image\/[a-z]+;base64,/, ''),
      pin: personValue?.pin,
      hireDate: personValue?.hireDate,
      certType: personValue?.certType,
      certNumber: personValue?.certNumber,
      whatsappMobileNo: personValue?.whatsappMobileNo,
      position: personValue?.position,
      deptCode: personValue?.deptCode,
    };

    personAddApi
      .addPerson(request)
      .then((response) => {
        if (response.data.code === 0) {
          // Handle successful response
          dispatch(
            setNotificationError({
              error: t(id ? 'personEditedSuccessfully' : 'personAddedSuccessfully'),
              status: 200, // Success status code
            }),
          );
          dispatch(addPerson(response.data.data));
          navigate('/admin/personnel/personnel/person');
        } else {
          // Handle specific error response
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const back = () => {
    navigate('/admin/personnel/personnel/person');
  };
  useEffect(() => {
    dispatch(fetchAllDepartmentList({})); // Fetch department list on component mount with empty object or required params
    dispatch(fetchAllPositionList({}));
  }, [dispatch]);
  return (
    <div className="person">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('addPerson')}
        broadCram={[{ title: t('personnel') }, { title: t('person') }, { title: t('addPerson') }]}
      />
      <WidgetForm
        rightContent={
          <Button onClickFunction={back} className="btn-default btn-sm" title={t('back')} />
        }
        backFunction={() => navigate('/admin/personnel/personnel/person')}
        tabs={[
          {
            title: t('personalDetails'),
            key: 'personalDetails',
            value: 1,
            content: <PersonalDetails onChangeFunction={onChangeFunction} values={personValue} />,
          },
          {
            title: t('professionalDetails'),
            key: 'professionalDetails',
            value: 2,
            content: (
              <ProfessionalDetails
                onChangeFunction={onChangeFunction}
                values={personValue}
                allDepartments={allDepartments} // Pass the departments data
                allPositions={allPositions}
              />
            ),
          },
          {
            title: t('permissions'),
            key: 'permissions',
            value: 3,
            content: <Permissions onChangeFunction={onChangeFunction} values={personValue} />,
          },
        ]}
        submitFunction={submit}
        data={personValue}
        firstStepValidation={[]}
        secondStepValidation={[personValue.pin, personValue.deptCode]}
      />
    </div>
  );
}

export default AddPerson;

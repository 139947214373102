import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import SelectedData from '../../../shared-components/selected-data/selected-data';
import Button from '../../../shared-components/button/button';

// Use svgicon components
import SearchIcon from '../../../assets/svgicons/svgicon';

// Use interface components
import { Level, AddLevelModalProps } from '../../../interface/permissions.interface';

function AddLevelModal({ onClose, onAddLevel, checkboxTreeData }: AddLevelModalProps) {
  const { t } = useTranslation();

  const initialLevels: any[] = [
    { levelName: 'Level 1', timezone: 'GMT', id: '1' },
    { levelName: 'Level 2', timezone: 'EST', id: '2' },
    { levelName: 'Level 3', timezone: 'EST', id: '3' },
  ];

  const header = [
    {
      label: 'Level Name',
      key: 'levelName',
    },
    {
      label: 'Timezone',
      key: 'timezone',
    },
  ];

  const [levelName, setLevelName] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  // Filtered data based on search
  const filteredCheckboxTreeData = checkboxTreeData.filter((level) =>
    level.label.toLowerCase().includes(levelName.toLowerCase()),
  );

  const handleAdd = () => {
    onAddLevel(selectedLevels);
    onClose();
  };

  useEffect(() => {
    if (selectAll && selectedLevels.length !== filteredCheckboxTreeData.length) {
      setSelectAll(false);
    }

    if (selectedLevels.length > 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [selectedLevels, filteredCheckboxTreeData]);

  return (
    <>
      <div className="level-body">
        <div className="input-fields">
          <div className="search-container">
            <input
              type="text"
              placeholder={t('Search by level name, time zone')}
              value={levelName}
              onChange={(e) => setLevelName(e.target.value)}
              className="input-field"
              aria-label={t('Search by level name, time zone')}
            />
            <SearchIcon />
          </div>
        </div>
        <SelectedData
          selectFunction={setSelectedLevels}
          selectedValues={selectedLevels}
          values={initialLevels}
          header={header}
          fromName={t('')}
          toName={t('')}
        />
      </div>

      <div className="modal-footer">
        <button type="button" onClick={onClose} className="cancel-button">
          {t('Cancel')}
        </button>
        <Button
          onClickFunction={handleAdd}
          title={t('Done')}
          disabled={disabledButton}
          className="btn-primary"
        />
      </div>
    </>
  );
}

export default AddLevelModal;

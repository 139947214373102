import { type UIEvent, useEffect, useRef, useState } from 'react';

// Use common modules
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  type MRT_RowSelectionState,
} from 'material-react-table';
import { Typography } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Use interface
import { DataTableInterFace } from '../../interface/table.interface';

function Example({
  action = [],
  actionButtonStatus,
  columns,
  checkBoxActive,
  getFilterData,
  getSelectedData,
  fetchNextPage,
  assignFilterValue,
  assignSelectedData,
  isFetching,
  values,
  fixedColumn = [],
  totalRecords,
  height,
}: DataTableInterFace) {
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const defaultSelectedData =
    assignSelectedData.length > 0
      ? assignSelectedData.reduce((a, v) => ({ ...a, [v]: true }), {})
      : {};

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    assignFilterValue.columnFilters || [],
  );
  const [globalFilter, setGlobalFilter] = useState<string>(assignFilterValue.globalFilter || '');
  const [sorting, setSorting] = useState<MRT_SortingState>(assignFilterValue.sorting || []);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(defaultSelectedData);

  const isLoading = false;

  const getFilterFormat = () => {
    const filter: any = {};
    columnFilters.forEach((fil: any) => {
      filter[fil.id] = fil.value;
    });
    if (globalFilter) {
      filter.search = globalFilter;
    }
    if (sorting.length > 0) {
      filter.sortField = sorting[0].id;
      filter.sortOrder = sorting[0].desc ? 'desc' : 'asc';
    }
    return filter;
  };

  const fetchMoreOnBottomReached = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (
        scrollHeight - scrollTop - clientHeight === 0 &&
        !isFetching &&
        values.length < totalRecords
      ) {
        fetchNextPage();
      }
    }
  };

  const actionButtons = (rowData: any) =>
    action?.map((button): any => (
      <div className="table-menu" onClick={() => button.function(rowData)} role="presentation">
        {button.label}
      </div>
    ));
  useEffect(() => {
    try {
      getFilterData({
        filter: getFilterFormat(),
        columnFilters,
        globalFilter,
        sorting,
      });
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter]);

  // a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached, values]);

  useEffect(() => {
    setRowSelection(defaultSelectedData);
  }, [assignSelectedData]);

  const table = useMaterialReactTable({
    columns,
    data: values,
    enablePagination: false,
    enableColumnPinning: true,
    enableRowNumbers: false,
    enableColumnFilters: false,
    enableRowVirtualization: true,
    manualFiltering: true,
    enableRowActions: actionButtonStatus,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: t('action'),
      },
    },
    positionActionsColumn: 'last',
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: `${window.innerHeight - height}px` },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement), // add an event listener to the table container element
    },
    renderRowActionMenuItems: ({ row }) => actionButtons(row.original),
    onColumnFiltersChange: setColumnFilters,

    // Sorting order method
    onSortingChange: setSorting,
    manualSorting: true,

    // Global filter textbox
    positionGlobalFilter: 'left',
    onGlobalFilterChange: setGlobalFilter,
    muiSearchTextFieldProps: {
      placeholder: 'Search all data',
      sx: { minWidth: '300px', borderRadius: '8px' },
      variant: 'outlined',
    },

    // Check box of records
    enableRowSelection: checkBoxActive,
    positionToolbarAlertBanner: 'bottom',
    onRowSelectionChange: (
      updaterOrValue:
        | MRT_RowSelectionState
        | ((old: MRT_RowSelectionState) => MRT_RowSelectionState),
    ) => {
      setRowSelection((prevSelection) => {
        // Determine the new selection state
        const newSelection =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(prevSelection) // If it's a function, pass in the previous selection
            : updaterOrValue; // Otherwise, it's the new selection object
        // Loop through the new selection data
        Object.keys(newSelection).forEach((key) => {
          if (newSelection[key]) {
            // Add the row to the selection if it's checked
            newSelection[key] = true;
          } else {
            // Remove the row from the selection if it's unchecked
            delete newSelection[key];
          }
        });
        // Pass the currently selected rows to getSelectedData
        getSelectedData(Object.keys(newSelection));
        // Return the updated selection state
        return newSelection;
      });
    },
    muiSelectCheckboxProps: {
      color: 'primary',
    },
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        let selectValue = {};
        setRowSelection((prev) => {
          const returnValue = prev;
          if (returnValue[row.original.id]) {
            delete returnValue[row.original.id];
          } else {
            returnValue[row.original.id] = true;
          }
          selectValue = {
            ...returnValue,
          };
          return {
            ...returnValue,
          };
        });
        getSelectedData(Object.keys(selectValue));
      },
      selected: rowSelection[row.original.id],
      sx: {
        cursor: 'pointer',
      },
    }),

    renderBottomToolbarCustomActions: () => (
      <Typography>
        {t('fetched')} {values.length} {t('of')} {totalRecords} {t('totalRows')}.
      </Typography>
    ),
    initialState: {
      columnPinning: { left: ['mrt-row-select', ...fixedColumn], right: ['mrt-row-actions'] },
      showColumnFilters: columnFilters.length > 0,
      showGlobalFilter: true,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      rowSelection,
      showAlertBanner: false,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef, // get access to the virtualizer instance
    // rowVirtualizerOptions: { overscan: 4 },
  });

  return <MaterialReactTable table={table} />;
}

const queryClient = new QueryClient();

function DataTable(data: DataTableInterFace) {
  return (
    <QueryClientProvider client={queryClient}>
      <Example {...data} />
    </QueryClientProvider>
  );
}

export default DataTable;

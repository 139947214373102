import React, { useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';
import Button from '../../../shared-components/button/button';

function OpenCameraModal({
  closeModal,
  submitFunction,
}: {
  closeModal: () => void;
  submitFunction: (val: any) => void;
}) {
  const width = 500;
  const height = 500;

  const { webcamRef, detected, facesDetected }: any = useFaceDetection({
    faceDetectionOptions: {
      model: 'short',
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),
    camera: ({ mediaSrc, onFrame }: CameraOptions) =>
      new Camera(mediaSrc, {
        onFrame,
        width,
        height,
      }),
  });

  const { t } = useTranslation();
  const [value, setValue] = useState({ file: '' });
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setValue({ file: imageSrc });
  };
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="row">
          <Webcam
            audio={false}
            forceScreenshotSourceSize
            width={300}
            height={300}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
          {!detected && <div className="text-danger">{t('noFaceDetected')}</div>}
        </div>
        <div className="row">{value.file && <img src={value.file} alt="take_a_picture" />}</div>
        <div className="item row-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={capture}
              title={t('takeAImage')}
              disabled={!detected || facesDetected > 1}
              className="btn-primary btn-bold"
            />
            <Button
              onClickFunction={() => {
                submitFunction(value.file);
                closeModal();
              }}
              title={t('submit')}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenCameraModal;

import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import { ParameterInterface } from '../../../interface/parameter.interface';

function RegistrationClient({ onChangeFunction, values }: ParameterInterface) {
  const { t } = useTranslation();
  const [value, setValue] = useState(values);
  const selectData = [
    {
      label: 'Value 1',
      id: 'value1',
    },
    {
      label: 'Value 2',
      id: 'value2',
    },
    {
      label: 'Value 3',
      id: 'value3',
    },
    {
      label: 'Value 4',
      id: 'value4',
    },
  ];

  const onChange = (name: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  return (
    <div className="personnel-setting">
      <div className="personnel-setting-form w100">
        <div className="row row-2">
          <div className="col">
            <div className="title">{t('certificateRecognition')}</div>
            <div className="row row-3">
              <div className="item">
                <RadioButtonField
                  label=""
                  name="certificateType"
                  aliment="row"
                  required
                  data={[
                    {
                      label: t('ocr'),
                      id: 'ocr',
                      value: 'ocr',
                    },
                    {
                      label: t('idReader'),
                      id: 'idReader',
                      value: 'idReader',
                    },
                  ]}
                  value={value.certificateType}
                  onChangeFunction={onChange}
                />
              </div>
              <div className="item">
                <SelectBoxField
                  name="registrationCode"
                  label={t('registrationCode')}
                  id="registrationCode"
                  value={value.registrationCode}
                  required
                  onChangeFunction={(name, val) => {
                    onChange(name, val.id);
                  }}
                  data={selectData}
                />
              </div>
              <div className="item">
                <Button onClickFunction={() => {}} title={t('register')} className="btn-default" />
              </div>
            </div>
            {value.certificateType === 'ocr' ? (
              <>
                <div className="row">
                  <div className="item">
                    <RadioButtonField
                      label=""
                      name="pers.ocrDriveVerson"
                      aliment="row"
                      required
                      data={[
                        {
                          label: t('downloadOCRv1Drive'),
                          id: '1',
                          value: '1',
                        },
                        {
                          label: t('downloadOCRv2Drive'),
                          id: '2',
                          value: '2',
                        },
                      ]}
                      value={value['pers.ocrDriveVerson']}
                      onChangeFunction={onChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="item">
                    <RadioButtonField
                      label={t('certificateNoAutomaticBackfillType')}
                      name="pers.ocrCertNoType"
                      aliment="row"
                      required
                      data={[
                        {
                          label: t('documentNo'),
                          id: '1',
                          value: '1',
                        },
                        {
                          label: t('personalNo'),
                          id: '2',
                          value: '2',
                        },
                      ]}
                      value={value['pers.ocrCertNoType']}
                      onChangeFunction={onChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className="item">
                  <RadioButtonField
                    label=""
                    name="pers.IDReadMode"
                    aliment="row"
                    required
                    data={[
                      {
                        label: t('downloadIDReaderDriver'),
                        id: '1',
                        value: '1',
                      },
                    ]}
                    value={value['pers.IDReadMode']}
                    onChangeFunction={onChange}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col">
            <div className="title">Card Printing</div>
            <div className="row row-2">
              <div className="item">
                <SelectBoxField
                  name="cardRegistrationCode"
                  label={t('registrationCode')}
                  id="cardRegistrationCode"
                  value={value.cardRegistrationCode}
                  required
                  onChangeFunction={(name, val) => {
                    console.log(name, val);
                    onChange(name, val.id);
                  }}
                  data={selectData}
                />
              </div>
              <div className="item">
                <Button onClickFunction={() => {}} title={t('register')} className="btn-default" />
              </div>
            </div>
            <div className="row row-3">
              <div className="link-tag">{t('downloadDrive')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationClient;

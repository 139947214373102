import React from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shard components
import Button from '../button/button';

// Use interface
import { DeleteModalBoxInterFace } from '../../interface/common.interface';

function DeleteModalBox({ closeModal, id, okFunction }: DeleteModalBoxInterFace) {
  const { t } = useTranslation();
  return (
    <div className="delete-box">
      <div className="message-text">
        <p>{t('areYouSureYouWantToPerformTheDeleteOperation?')}</p>
      </div>
      <div className="confirm-buttons">
        <Button onClickFunction={closeModal} title={t('Cancel')} className="btn-default" />
        <Button onClickFunction={() => okFunction(id)} title={t('ok')} className="btn-primary" />
      </div>
    </div>
  );
}

export default DeleteModalBox;

import React, { useEffect, useState } from 'react';

// Use interface
import { TabPageInterFace, ModalTabItemInterFace } from '../../interface/common.interface';

function TabPages({ tabs, defaultNumber }: TabPageInterFace) {
  const [tab, changeTab] = useState(tabs[defaultNumber]);

  useEffect(() => {
    changeTab(tabs[defaultNumber]);
  }, [defaultNumber]);
  return (
    <div className="tab-page">
      <div className="tab-modal-content">
        <div className="tab-list-items">
          {tabs.map((tabItem: ModalTabItemInterFace) => (
            <div
              className={`tab-item ${tabItem.key === tab.key && 'active'}`}
              onClick={() => changeTab(tabItem)}
              role="presentation">
              {tabItem.title}
            </div>
          ))}
        </div>
        <div className="tab-body">{tab.content}</div>
      </div>
    </div>
  );
}

export default TabPages;
